@import "../index.css";

.inputSizePrimary {
  height: 48px;
  width: 260px;
  border-radius: 100px;
}

.primaryButton,
.primaryButton:hover,
.primaryButton:active,
.primaryButton:focus {
  background-color: var(--c-accent);
  color: var(--c-highlight-label);
  border: 0;
  font-weight: bold;
  border: none;
}

.inputForm {
  border-color: var(--c-sub-label);
  box-shadow: none;
  background: #00000000;
  color: var(--c-sub-label);
}

.inputForm:focus {
  border-color: var(--c-accent);
  box-shadow: none;
  background: #00000000;
  color: var(--c-sub-label);
}

.inputFormError {
  border-color: var(--c-red);
  box-shadow: none;
  background: #00000000;
  color: var(--c-sub-label);
}

.errorAltert {
  color: var(--c-red);
  text-align: center;
}

.primaryTitle1 {
  text-align: center;
  color: var(--c-label);
  margin: 70px;
}

.primarySubTitle {
  font-size: 10px;
  text-align: center;
  margin: 20px;
  color: var(--c-sub-label);
}

.externalLogin {
  background-color: var(--c-card-background);
  border-radius: 100%;
  width: 60px;
  height: 60px;
}

.flexCloumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.appleLogo {
  color: var(--c-label);
}

.loginWrapper {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  background-color: #00000000;
}

.loginWrapper-transparent {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: absolute;
  top: 0;
  background-color: #00000000;
}

.primaryButton.inputSizePrimary {
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.primaryButton.inputSizePrimary:active {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

.px-card,
.px-card:hover,
.px-card:focus {
  box-shadow: 0 2px 8px 0 var(--c-shadow-color);
}

.px-card:active {
  box-shadow: 0 2px 4px 0 var(--c-shadow-color);
}

@media (prefers-color-scheme: dark) {
  .px-card,
  .px-card:hover,
  .px-card:focus,
  .px-card:active {
  }
}
