body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #00000000;
  /* background-color: #FFFFFF; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --c-card-background: #ffffff;
  --c-accent: #9b43ef;

  --c-highlight-label: #ffffff;
  --c-label: #000000;
  --c-primary-label: #2d3142;
  --c-sub-label: #737889;

  --c-shadow-color: #73788950;

  --c-red: #ff3b30;
}

@media (prefers-color-scheme: dark) {
  :root {
    --c-default-background: #000000;
    --c-card-background: #18181b;
    --c-accent: #c17fff;

    --c-highlight-label: #ffffff;
    --c-label: #ffffff;
    --c-primary-label: #d8d9dc;
    --c-sub-label: #aab2c4;

    --c-shadow-color: #00000000;

    --c-red: #ff453a;
  }
}
